import { ofType } from 'redux-observable';
import { switchMap, map, tap } from 'rxjs/operators';
import { retryWithToast } from 'behavior/errorHandling';
import {
  CHECKOUT_CREDITNOTELIMIT_REQUESTED,
  checkoutInfoUpdated,
} from './actions';
import { addCreditNoteLimitMutation } from './queries';
import { skipIfPreview } from 'behavior/preview';
import { navigateOnIncorrect } from './helpers';

export default function createEpic(waitForSubmit) {
  return function (action$, state$, { api, logger }) {

    const isPromotion = () => !!state$?.value?.page?.info?.quote ?? false;
    const isQuote = () => state$?.value?.page?.info?.isQuote ?? false;

    return action$.pipe(
      ofType(CHECKOUT_CREDITNOTELIMIT_REQUESTED),
      skipIfPreview(state$),
      switchMap(({ payload }) => waitForSubmit(() => api.graphApi(addCreditNoteLimitMutation(isPromotion()), {
        creditLimitNoteAplied: payload.info,
        asQuote: isQuote(),
        maxLines: state$.value.settings.checkout.maxOverviewLines + 1,
      }).pipe(
        map(({ checkout }) => {
          if (checkout) {
            const selectResult = checkout.creditLimitNoteAplied;
            if (selectResult.creditLimitNoteAplied) {
              return checkoutInfoUpdated(selectResult.info);
            }
          }
          return navigateOnIncorrect(state$.value.page.info);
        }),
        retryWithToast(action$, logger),
      ))),
    );
  };
}
