import styles from './DeliveryOrPickup.module.scss';
import { memo } from 'react';
import Popup from './Popup';
import { Modal, useModal } from 'components/objects/modals';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { useSelector } from 'react-redux';
import DeliveryOrPickupLink from 'components/objects/deliveryOrPickup/DeliveryOrPickupLink';
import PropTypes from 'prop-types';

const DeliveryOrPickupBase = ({  options: { showIconInDesktop }, isMobile, isSticky }) => {
  const { opened, show, hide } = useModal();
  const [msg, delivery] = useSanaTexts(['DeliveryOrPickupLinkMessage', 'Delivery']).texts;
  const { stores, currentStoreCode } = useSelector(({ branches }) => ({
    stores: branches.stores,
    currentStoreCode: branches.selectedStoreCode,
  }));

  const getMessage = () => {
    if(stores){
      const currentStore = stores.filter(store => store.id === currentStoreCode);
      if(currentStore.length>0)
        return makeSimpleText(msg, [currentStore[0].name]);
    }
    return makeSimpleText(msg, [delivery]);
  };

  return (
    <>
      <DeliveryOrPickupLink
        message={getMessage()}
        show={show}
        isMobile={showIconInDesktop ? true : isMobile}
        isSticky={isSticky}
        isDelivery={currentStoreCode === null}
        showIconInDesktop={showIconInDesktop}
      />
      <Modal constentClassName={styles.overflowYUnset} hide={hide} opened={opened} size={'medium'} resetContentOnClose={true}>
        <Popup stores={stores} hide={hide} />
      </Modal>
    </>
  );
};

DeliveryOrPickupBase.propTypes = {
  options: PropTypes.shape({
    showIconInDesktop: PropTypes.bool,
  }).isRequired,
  isMobile: PropTypes.bool,
  isSticky: PropTypes.bool,
  isDesignerMode: PropTypes.bool,
};

export default memo(DeliveryOrPickupBase);
