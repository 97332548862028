export const STORES_LOADED = 'STORES_LOADED';
export const storesLoaded = stores =>{
  return ({ type: STORES_LOADED, payload: stores });
};

export const LOAD_SLOT_TIME_ORDER_REVEIVED = 'LOAD_SLOT_TIME_ORDER_REVEIVED';
export const loadSlotTimeOrderReveived = slotTimeOrderLog =>{
  return ({ type: LOAD_SLOT_TIME_ORDER_REVEIVED, payload: slotTimeOrderLog });
};

export const LOAD_SLOT_TIME_ORDER_REQUEST = 'LOAD_SLOT_TIME_ORDER_REQUEST';
export const loadSlotTimeOrderRequest = options =>{
  return ({ type: LOAD_SLOT_TIME_ORDER_REQUEST, payload: options });
};

export const CLEAR_SLOT_TIME_ORDER_REQUEST = 'CLEAR_SLOT_TIME_ORDER_REQUEST';
export const clearSlotTimeOrderRequest = () =>{
  return ({ type: CLEAR_SLOT_TIME_ORDER_REQUEST });
};

export const SET_STORE_CODE_REQUEST = 'SET_STORE_CODE_REQUEST';
export const setStoreCodeRequest = storeCode => ({
  type: SET_STORE_CODE_REQUEST,
  payload: storeCode,
});

export const SET_STORE_CODE_RECEIVED = 'SET_STORE_CODE_RECEIVED';
export const setStoreCodeReceived = storeCode => ({
  type: SET_STORE_CODE_RECEIVED,
  payload: storeCode,
});

export const CLEAR_STORE_CODE = 'CLEAR_STORE_CODE';
export const clearStoreCode = () => ({
  type: CLEAR_STORE_CODE,
});

export const SET_STORE_CODE_NOT_RECALCULATE = 'SET_STORE_CODE_NOT_RECALCULATE';
export const setStoreCodeNotRecalculate = () => ({
  type: SET_STORE_CODE_NOT_RECALCULATE,
});
