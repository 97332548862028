exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".DeliveryOrPickup_wrapper{display:-webkit-flex;display:flex}.DeliveryOrPickup_overflow-y-unset{overflow-y:unset}.DeliveryOrPickup_popup{min-height:16em}.DeliveryOrPickup_popup,.DeliveryOrPickup_popup .DeliveryOrPickup_body{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-align-items:center;align-items:center}.DeliveryOrPickup_popup .DeliveryOrPickup_body{margin-top:1em}.DeliveryOrPickup_select{min-width:20em}button.DeliveryOrPickup_popup-btn{display:block;margin-top:1em}.DeliveryOrPickup_options-container{max-height:8em!important}.DeliveryOrPickup_mobile-btn .DeliveryOrPickup_store-alt-icon,.DeliveryOrPickup_sticky-content .DeliveryOrPickup_store-alt-icon{display:inline-block;vertical-align:bottom;max-width:22px;max-height:22px}.DeliveryOrPickup_mobile-btn svg.DeliveryOrPickup_store-alt-icon,.DeliveryOrPickup_sticky-content svg.DeliveryOrPickup_store-alt-icon{width:22px;height:22px;color:var(--loginInfo_Icon_Color,#cb2245)}.DeliveryOrPickup_sticky-content{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;line-height:22px;margin:0 -4px}.DeliveryOrPickup_sticky-content .DeliveryOrPickup_store-alt-icon{margin:0 4px}.DeliveryOrPickup_sticky-content>div,.DeliveryOrPickup_sticky-content>span{text-align:center;margin:0 4px}.DeliveryOrPickup_mobile-btn{border:solid var(--header_BorderColor,#e6e6e6);border-width:0 0 0 1px;background-color:transparent}.DeliveryOrPickup_mobile-btn,.DeliveryOrPickup_sticky-btn{width:62px;height:62px;-webkit-align-items:center;align-items:center;display:-webkit-flex;display:flex;-webkit-justify-content:center;justify-content:center;cursor:pointer}", ""]);

// exports
exports.locals = {
	"wrapper": "DeliveryOrPickup_wrapper",
	"overflow-y-unset": "DeliveryOrPickup_overflow-y-unset",
	"overflowYUnset": "DeliveryOrPickup_overflow-y-unset",
	"popup": "DeliveryOrPickup_popup",
	"body": "DeliveryOrPickup_body",
	"select": "DeliveryOrPickup_select",
	"popup-btn": "DeliveryOrPickup_popup-btn",
	"popupBtn": "DeliveryOrPickup_popup-btn",
	"options-container": "DeliveryOrPickup_options-container",
	"optionsContainer": "DeliveryOrPickup_options-container",
	"mobile-btn": "DeliveryOrPickup_mobile-btn",
	"mobileBtn": "DeliveryOrPickup_mobile-btn",
	"store-alt-icon": "DeliveryOrPickup_store-alt-icon",
	"storeAltIcon": "DeliveryOrPickup_store-alt-icon",
	"sticky-content": "DeliveryOrPickup_sticky-content",
	"stickyContent": "DeliveryOrPickup_sticky-content",
	"sticky-btn": "DeliveryOrPickup_sticky-btn",
	"stickyBtn": "DeliveryOrPickup_sticky-btn"
};