import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import { Container } from 'components/primitives/grid';
import PageBreadcrumb from 'components/objects/pageBreadcrumb';
import { PageBreadcrumbProductDetail } from 'components/objects/pageBreadcrumb';
import BackLink from './BackLink';

const Details = loadable(() => import(/*webpackChunkName:"pdp"*/'./Details'));

const DetailsPage = ({ preset, middleContent, footerContent }) => (
  <>
    <Container>
      <PageBreadcrumbProductDetail backLinkComponent={BackLink} />
    </Container>
    <Details preset={preset} middleContent={middleContent} footerContent={footerContent} />
  </>
);

DetailsPage.propTypes = {
  preset: PropTypes.string.isRequired,
  middleContent: PropTypes.array,
  footerContent: PropTypes.array,
};

export default DetailsPage;
