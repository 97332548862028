import { of, identity } from 'rxjs';
import { map, first, mergeMap } from 'rxjs/operators';
import { PageComponentNames } from 'behavior/pages';
import { initSystemPageContent } from 'behavior/pages/system';
import { RouteName, routesBuilder } from 'routes';
import { requestRoute } from 'behavior/route';
import { redirectTo } from 'behavior/routing';
import { DocumentType } from 'behavior/documents';
import { areLocationsEqual } from 'behavior/routing/helpers';
import { CheckoutPresets } from 'behavior/settings/constants';
import { CustomerTypes } from 'behavior/user';
import { requestAbility } from 'behavior/user/epic';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { toUrlHash } from 'utils/url';
import { iEquals } from 'utils/helpers';
import {
  filterImages,
  getStepByUrlHash,
  updateShippingAddressIfNeeded,
  adjustShippingMethodData,
  adjustPaymentMethodData,
  adjustGuestProfileData,
  adjustCheckoutAddresses,
} from './helpers';
import { createCheckoutPageQuery, createPromotionPageQuery, createCheckoutPreviewPageQuery, getRefreshCheckoutQuery } from './queries';
import { Steps, ShippingAddressOption } from './constants';
import { getProductsTrackingDataFromLines } from 'behavior/analytics';
import { catchBasketCalculationError } from 'behavior/errorHandling';

export default function (routeData, state$, dependencies) {
  const state = state$.value;
  const canLoadPage = ({ user, settings, basket }) => user.initialized
    && settings.loaded
    && !settings.updating
    && !basket.updating;

  if (!canLoadPage(state)) {
    return state$.pipe(
      first(canLoadPage),
      mergeMap(_ => handle(routeData, state$, dependencies)),
    );
  }

  return handle(routeData, state$, dependencies);
}

function handle({ routeName, params, options }, state$, dependencies) {
  const state = state$.value;

  if (state.user.customer && !state.user.customer.isValid)
    return of(null);

  let asQuote = params && params.asQuote || false;
  if (typeof asQuote === 'string')
    asQuote = iEquals(asQuote, 'true');

  const isGuest = isGuestCheckout(routeName, params, state);
  let currentStep = options?.step;

  const isPromotion = isQuotePromotion(routeName);

  if (currentStep && areLocationsEqual(state.routing.location, state.routing.navigatingTo.location, true)) {
    const page = { ...state.page };
    const stepInvalid = options?.stepInvalid;
    page.info = { ...page.info, currentStep, stepInvalid };

    if (!stepInvalid)
      return of({ page });

    return dependencies.api.graphApi(getRefreshCheckoutQuery(isGuest, isPromotion), {
      asQuote,
      maxLines: state.settings.checkout.maxOverviewLines + 1,
    }).pipe(
      mergeMap(({ checkout: checkoutInfo, viewer }) => {
        if (!checkoutInfo)
          return of({ page });

        adjustPaymentMethodData(checkoutInfo);
        adjustShippingMethodData(checkoutInfo);
        adjustGuestProfileData(checkoutInfo);

        delete checkoutInfo.steps;
        page.info = { ...page.info, ...checkoutInfo };
        if (isGuest)
          return of({ page });

        adjustCheckoutAddresses(page.info, viewer);
        return updateShippingAddressIfNeeded(page.info, state$, dependencies).pipe(
          mergeMap(updateAddress => of({ page, action: updateAddress })),
        );
      }),
    );
  }

  currentStep = currentStep || (
    dependencies.scope === 'CLIENT'
      ? getStepByUrlHash(state.routing.navigatingTo.location.hash)
      : Steps.None
  );

  const settings = state.settings;
  const isMultiStep = settings.checkout.pagePreset === CheckoutPresets.MultiStep;
  const maxOverviewLines = settings.checkout.maxOverviewLines;

  if (params?.previewToken)
    return _handlePreview();

  if (!state.user.isAuthenticated) {
    if (!isGuest)
      return of({ statusCode: 401 });

    return requestAbility(AbilityTo.CheckoutAsGuest, state$, dependencies).pipe(
      mergeMap(guestCheckoutAbility => guestCheckoutAbility !== AbilityState.Available
        ? of({ statusCode: 401 })
        : _handle(),
      ),
    );
  }

  return _handle();

  function _handle() {
    const loadShortInfo = settings
      && settings.analytics
      && settings.analytics.trackers
      && settings.analytics.trackers.length > 0;

    return dependencies.api.graphApi(isPromotion ? createPromotionPageQuery(isMultiStep) : createCheckoutPageQuery(isGuest, isMultiStep), {
      maxLines: maxOverviewLines + 1,
      loadShortInfo,
      asQuote,
    }).pipe(
      mergeMap(({
        pages,
        checkout,
        viewer,
        profile,
      }) => {
        if (!checkout || !pages.checkout)
          return of(null);

        if (!checkout.valid || !checkout.minimumTotalIsValid) {
          const routeToRedirect = isPromotion ? routesBuilder.forQuotes() : routesBuilder.forBasket();

          return requestRoute(routeToRedirect, state$, dependencies).pipe(
            map(path => ({
              action$: of(redirectTo(path, 302, routeToRedirect)),
            })),
          );
        }

        if (checkout.editDocumentType && (checkout.editDocumentType === DocumentType.Quote) !== asQuote) {
          const checkoutRoute = routesBuilder.forCheckout(!asQuote, currentStep);
          const urlHash = currentStep !== Steps.None ? toUrlHash(currentStep) : '';

          return requestRoute(checkoutRoute, state$, dependencies).pipe(
            map(checkoutPath => ({
              action$: of(redirectTo(checkoutPath + urlHash, 302, checkoutRoute)),
            })),
          );
        }

        if (loadShortInfo) {
          const analytics = { products: getProductsTrackingDataFromLines(checkout.productLines.shortInfoList) };
          checkout.analytics = analytics;
        }

        const page = pages.checkout;
        page.component = PageComponentNames.Checkout;
        page.info = checkout;
        page.info.currentStep = currentStep;

        adjustPaymentMethodData(page.info);
        adjustShippingMethodData(page.info);

        const profileFields = profile?.profileFields;

        if (!isGuest) {
          const customer = viewer && viewer.customer;

          page.authentication = { required: true };
          page.info.billingAddress = customer && customer.billingAddress;
          page.info.shippingAddresses = customer && customer.shippingAddresses;
          page.info.isQuote = asQuote;

          if (profileFields) {
            page.info.canEditBilling = canEditBilling(page.info.billingAddress, profileFields);
            page.info.canEditEmail = canEditEmail(state.user, profileFields);
          }
        } else {
          page.info.isGuest = true;
          page.info.profileTemplateFields = profileFields;
          page.info.profileFieldsPickupInStore = profile?.profileFieldsPickupInStore;
          adjustGuestProfileData(page.info);
        }

        filterImages(checkout);

        if (isGuest)
          return of({ page });

        return updateShippingAddressIfNeeded(page.info, state$, dependencies)
          .pipe(map(action => ({ page, action })));
      }),
      initSystemPageContent(),
      isPromotion
        ? identity
        : catchBasketCalculationError(error => {
          const routeToRedirect = routesBuilder.forBasket();

          return requestRoute(routeToRedirect, state$, dependencies).pipe(
            map(path => ({
              action$: of(redirectTo(path, 302, routeToRedirect)),
            })),
          );
        }),
    );
  }

  function _handlePreview() {
    return dependencies.api.graphApi(createCheckoutPreviewPageQuery(isGuest, isMultiStep)).pipe(
      mergeMap(({ pages: { checkout: page }, checkout, profile }) => {
        if (!page)
          return of(null);

        page.component = PageComponentNames.Checkout;

        const shippingAddresses = Array.from(Array(2)).map((_, index) => ({ id: index.toString(), formatted: '' }));
        const shippingMethods = Array.from(Array(3)).map((_, index) => ({ id: index.toString(), name: '-' }));
        const productLinesList = Array.from(Array(Math.min(3, maxOverviewLines))).map((_, index) => ({
          product: { id: (index + 1).toString(), title: '' },
          serviceLines: [],
          quantity: 1,
          uom: { id: '' },
        }));
        page.info = {
          valid: true,
          currentStep,
          billingAddress: { formatted: '-', isPrimary: true },
          shippingAddress: { templateFields: checkout.shippingAddress.templateFields, address: shippingAddresses[0], shippingOption: ShippingAddressOption.Existing },
          shippingAddresses,
          isQuote: false,
          steps: [{ id: Steps.Address }, { id: Steps.Shipping }, { id: Steps.Payment }, { id: Steps.Overview }],
          productLines: { list: productLinesList, totalCount: productLinesList.length },
          serviceLines: [],
          shippingMethods,
          shippingMethodId: shippingMethods[0].id,
          totals: { sub: 0, roundOff: 0, priceExcludingTax: 0, price: 0 },
          discount: { invoice: 0, payment: 0 },
          tax: {},
        };

        if (state.user.customerType === CustomerTypes.B2C) {
          const paymentMethods = Array.from(Array(3)).map((_, index) => ({ id: index.toString(), name: '-' }));
          page.info.paymentMethods = paymentMethods;
          page.info.paymentMethodId = paymentMethods[0].id;
        }

        const profileFields = profile?.profileFields;

        if (!isGuest) {
          if (profileFields) {
            page.info.canEditBilling = canEditBilling(page.info.billingAddress, profileFields);
            page.info.canEditEmail = canEditEmail(state.user, profileFields);
          }
        } else {
          page.info.isGuest = true;
          page.info.profileTemplateFields = profileFields;
          page.info.profileFieldsPickupInStore = profile?.profileFieldsPickupInStore;
        }

        return of({ page });
      }),
      initSystemPageContent(),
    );
  }
}

function isQuotePromotion(routeName) {
  return routeName === RouteName.QuotePromotion;
}

function isGuestCheckout(routeName, params, state) {
  if (state.user.isAuthenticated || isQuotePromotion(routeName))
    return false;

  return params?.guest?.toString() === 'true' || params?.previewToken != null;
}

function canEditBilling(billingAddress, profileFields) {
  return billingAddress?.isPrimary && profileFields.some(f => f.type === 'EditableTemplateField');
}

function canEditEmail(user, profileFields) {
  return user.customerType === CustomerTypes.B2C
    && profileFields.some(f => f.name.includes('Email') && f.type === 'EditableTemplateField');
}
