import styles from './DeliveryOrPickup.module.scss';
import modalStyles from 'components/objects/modals/Modal.module.scss';
import { memo, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import Select from '../../primitives/form/Select';
import { SanaButton } from 'components/primitives/buttons';
import { useSanaTexts } from 'components/sanaText';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { setStoreCodeRequest } from 'behavior/stores';
import { useEffect } from 'react';

const Popup = ({ stores, hide }) => {

  const hasStores = stores && !!stores.length;
  const [delivery] = useSanaTexts(['Delivery']).texts;
  const currentStoreCode = useSelector(({ branches }) => branches.selectedStoreCode);
  const [storeCodeSelected, setStoreIdSelected] = useState(currentStoreCode);
  const dispatch = useDispatch();

  const onStoreChange = storeId => {
    setStoreIdSelected(storeId);
  };

  const onSetStore = ()=>{
    if(currentStoreCode !== storeCodeSelected)
      dispatch(setStoreCodeRequest(storeCodeSelected));

    hide();
  };

  useEffect(()=>{
    setStoreIdSelected(currentStoreCode);
  },[currentStoreCode]);

  const items = useMemo(() => {
    const items = [{ name: delivery, value: null }];
    stores.map(x=>items.push({ name:x.name,value:x.id }));
    return items;
  }, [stores]);

  return (
    <div className={`${styles.popup}`} x-ms-format-detection="none">
      <h2 className={`h2 ${modalStyles.title}`}><SimpleText textKey="DeliveryOrPickupTitlePopup" /></h2>
      <div>
        <SimpleText textKey="DeliveryOrPickupMessagePopup" />
      </div>
      {hasStores && (
        <div className={`${styles.body}`}>
          <Select
            items={items}
            id={'cbxStores'}
            value={storeCodeSelected}
            className={styles.select}
            optionContainerClassName={styles.optionsContainer}
            onChange={onStoreChange}
          />
          <SanaButton textKey="Submit_SelectWeek" onClick={onSetStore} className={`${btnStyles.btnSmall} ${styles.btnSubmit} ${styles.popupBtn}`} />
        </div>
      )}
    </div>
  );
};

Popup.propTypes = {
  stores: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
      id: PropTypes.string,
      address: PropTypes.string,
      address2: PropTypes.string,
      city: PropTypes.string,
      contactName: PropTypes.string,
      countryId: PropTypes.string,
      countryName: PropTypes.string,
      fax: PropTypes.string,
      name: PropTypes.string,
      phone: PropTypes.string,
      postalAddress: PropTypes.string,
      state: PropTypes.string,
      zipCode: PropTypes.string,
    }),
    openingTime: PropTypes.shape({
      days: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        timeBlock: PropTypes.arrayOf(PropTypes.shape({
          from: PropTypes.string,
          to: PropTypes.string,
        })),
      })),
    }),
  })),
  hide: PropTypes.func.isRequired,
};

export default memo(Popup);