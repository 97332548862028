import styles from './DeliveryOrPickup.module.scss';
import PropTypes from 'prop-types';
import { StoreAltIcon, TruckIcon } from 'components/primitives/icons';

const Icon = ({ isDelivery }) => {
  if (isDelivery)
    return <TruckIcon className={`${styles.storeAltIcon}`} />;

  return <StoreAltIcon className={styles.storeAltIcon} />;
};

Icon.propTypes = {
  isDelivery: PropTypes.bool,
};

export default Icon;
