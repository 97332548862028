import styles from './StyleWrapper.module.scss';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useResponsiveBreakpoints } from 'utils/layout';
import { ContentHorizontalAlignment } from './constants';
import { joinClasses, getMinValue } from 'utils/helpers';

const StyleWrapper = ({
  children,
  spacing,
  minHeight,
  minWidth,
  horizontalAlignment,
  contentOrientation,
  stretchHeight,
  stretchWidth,
}) => {
  const { xs, sm, md } = useResponsiveBreakpoints();
  const actualMinHeight = useMemo(() => getMinValue(minHeight, xs, sm, md), [xs, sm, md, minHeight]);
  const actualMinWidth = useMemo(() => getMinValue(minWidth, xs, sm, md), [xs, sm, md, minWidth]);

  return (
    <div
      className={joinClasses(
        styles.wrapper,
        stretchHeight && (contentOrientation === 'VERTICAL' ? styles.flexStretchHeight : styles.stretchHeight),
        stretchWidth && (contentOrientation === 'HORIZONTAL' ? styles.flexStretchWidth : styles.stretchWidth),
      )}
      style={{
        padding: spacing.padding || null,
        margin: spacing.margin || null,
        minHeight: actualMinHeight,
        minWidth: actualMinWidth,
        textAlign: ContentHorizontalAlignment[horizontalAlignment] || null,
      }}
    >{children}</div>
  );
};

const PropTypesMinValue = PropTypes.shape({
  mobile: PropTypes.string,
  tablet: PropTypes.string,
  desktop: PropTypes.string,
}).isRequired;

StyleWrapper.propTypes = {
  children: PropTypes.any,
  spacing: PropTypes.shape({
    margin: PropTypes.string,
    padding: PropTypes.string,
  }),
  minHeight: PropTypesMinValue,
  minWidth: PropTypesMinValue,
  horizontalAlignment: PropTypes.oneOf(Object.keys(ContentHorizontalAlignment)),
  contentOrientation: PropTypes.string,
  stretchHeight: PropTypes.bool,
  stretchWidth: PropTypes.bool,
};

export default StyleWrapper;
