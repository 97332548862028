import { memo } from 'react';
import { useSelector } from 'react-redux';
import Icon from './Icon';
import linkStyles from 'components/primitives/links/Link.module.scss';
import styles from './DeliveryOrPickup.module.scss';
import PropTypes from 'prop-types';

const DeliveryOrPickupLink = ({ message, show, isMobile, isSticky, isDelivery, showIconInDesktop }) => {
  const isLoading = useSelector(state => state.isLoading);
  if(isMobile)
  {
    return (
      <div className={`${styles.wrapper} ${ isSticky || showIconInDesktop ? '' : styles.mobileBtn }`}>
        <button disabled={isLoading} className={`${linkStyles.link} ${styles.stickyBtn}`} onClick={show}  title={message}>
          <div className={styles.stickyContent}>
            <Icon isDelivery={isDelivery} />
          </div>
        </button>
      </div>
    );
  }else{
    return (
      <button disabled={isLoading} className={`${linkStyles.link}`} onClick={show}>
        { message }
      </button>
    );
  }
};

DeliveryOrPickupLink.propTypes = {
  message: PropTypes.string,
  show: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  isSticky: PropTypes.bool,
  isDelivery: PropTypes.bool,
  showIconInDesktop: PropTypes.bool,
};

export default memo(DeliveryOrPickupLink);
