import PropTypes from 'prop-types';
import DeliveryOrPickupBase from './DeliveryOrPickupBase';

const DeliveryOrPickupBlock = ({ model, isDesignerMode }) => (
    <DeliveryOrPickupBase options={model} isDesignerMode={isDesignerMode} />
);

DeliveryOrPickupBlock.propTypes = {
  model: PropTypes.shape({
    showIconInDesktop: PropTypes.bool,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
};

export default DeliveryOrPickupBlock;
