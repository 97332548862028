import { merge, of } from 'rxjs';
import { switchMap, map, pluck, mergeMap } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import {
  storesLoaded,
  loadSlotTimeOrderReveived,
  LOAD_SLOT_TIME_ORDER_REQUEST,
  SET_STORE_CODE_REQUEST,
  SET_STORE_CODE_RECEIVED,
  setStoreCodeReceived,
  setStoreCodeNotRecalculate,
} from './actions';
import { APP_INIT } from 'behavior/app';
import { loadStoresQuery, loadSlotTimeOrderLog } from './queries';
import { requestCalculatedFields } from 'behavior/pages/productList';
import { updateCalculatedFields } from 'behavior/pages/product';
import { modifyBasket } from 'behavior/basket';
import { requestLastViewedProducts } from 'behavior/products/lastViewedTracking';
import { toasts } from 'behavior/toasts';
import { UserTypes } from 'behavior/user/constants';
import { userSetLastStoreCode } from './../user/actions';
import { requestLines, selectShippingMethod, selectAddress } from 'behavior/pages/checkout';

export default (action$, state$, dependencies) => {
  const { api } = dependencies;

  const OPTIONS_STORE_CODE_UPDATED = {
    productList: (page, storeCodeInfo) => {
      const options = {
        ids: page.products.map(p => p.id),
        page: { size: page.products.length, index: 0 },
      };
      return of(requestCalculatedFields(options), userSetLastStoreCode(storeCodeInfo));
    },
    productDetails: (page, storeCodeInfo) => {
      const options = { ids: [page.product.id], page: { size: 1, index: 0 } };
      return of(updateCalculatedFields(options), userSetLastStoreCode(storeCodeInfo));
    },
    Search: (page, storeCodeInfo) => {
      const options = {
        ids: page.products.map(p => p.id),
        page: { size: page.products.length, index: 0 },
      };
      return of(requestCalculatedFields(options), userSetLastStoreCode(storeCodeInfo));
    },
    checkout: (page, storeCodeInfo) => {
      toasts.warning('', { textKey: 'StoreSelectedInCheckoutIsChanged_Warning' });
      const { info } = page;
      const dispatchs = [];
      if(storeCodeInfo.accountId && storeCodeInfo.storeCode){
        dispatchs.push(selectShippingMethod(null, null));
      }else{
        dispatchs.push(selectShippingMethod(info.lastShippingMethodId || null, null));
      }
      if(!storeCodeInfo.storeCode)
        dispatchs.push(selectAddress());
      return of(...dispatchs, requestLines(), modifyBasket([], null, true, 0), userSetLastStoreCode(storeCodeInfo));
    },
    basket: (page, storeCodeInfo) => {
      return of(modifyBasket([], null, true, 0), userSetLastStoreCode(storeCodeInfo));
    },
    lastViewedProducts: (page, storeCodeInfo) => {
      return of(requestLastViewedProducts(false, 5), userSetLastStoreCode(storeCodeInfo));
    },
  };

  const storesRequest$ = action$.pipe(
    ofType(APP_INIT),
    switchMap(() =>
      api.graphApi(loadStoresQuery).pipe(pluck('stores'), map(storesLoaded)),
    ),
  );

  const setStoreCodeRequest$ = action$.pipe(
    ofType(SET_STORE_CODE_REQUEST),
    pluck('payload'),
    map(storeCode => {
      api.setStoreCode(storeCode);
      return setStoreCodeReceived(storeCode);
    }),
  );

  const setStoreCodeReceived$ = action$.pipe(
    ofType(SET_STORE_CODE_RECEIVED),
    pluck('payload'),
    mergeMap(() => {
      const page = state$.value.page;
      const isGuestUser = (state$.value.user.type === UserTypes.Guest || state$.value.user.type === UserTypes.Anonymous);
      const storeCodeInfo = {
        storeCode: state$.value.branches.selectedStoreCode,
        accountId: null,
      };
      if(!isGuestUser)
        storeCodeInfo.accountId = state$.value.user.id;

      return OPTIONS_STORE_CODE_UPDATED[page.component]
        ? OPTIONS_STORE_CODE_UPDATED[page.component](page, storeCodeInfo)
        : of(setStoreCodeNotRecalculate(), userSetLastStoreCode(storeCodeInfo));
    }),
  );

  const loadSlotTimeOrderRequest$ = action$.pipe(
    ofType(LOAD_SLOT_TIME_ORDER_REQUEST),
    switchMap(action => {
      return api
        .graphApi(loadSlotTimeOrderLog, { options: action.payload })
        .pipe(pluck('slotTimeOrderLog'), map(loadSlotTimeOrderReveived));
    }),
  );

  return merge(storesRequest$, setStoreCodeRequest$, setStoreCodeReceived$, loadSlotTimeOrderRequest$);
};
