import { STORES_LOADED,
  LOAD_SLOT_TIME_ORDER_REVEIVED,
  CLEAR_SLOT_TIME_ORDER_REQUEST,
  SET_STORE_CODE_RECEIVED,
  CLEAR_STORE_CODE } from './actions';

export const initialState = {
  stores: null,
  slotsTime:{},
  loaded: false,
  selectedStoreCode:null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STORES_LOADED:
      return {
        ...state,
        stores: action.payload,
        loaded: true,
      };
    case SET_STORE_CODE_RECEIVED:
      return {
        ...state,
        selectedStoreCode: action.payload,
      };
    case CLEAR_STORE_CODE:
      return {
        ...state,
        selectedStoreCode: null,
      };
    case LOAD_SLOT_TIME_ORDER_REVEIVED:
      return {
        ...state,
        slotsTime: action.payload,
      };
    case CLEAR_SLOT_TIME_ORDER_REQUEST:
      return {
        ...state,
        slotsTime: {},
      };
    default:
      return state;
  }
};