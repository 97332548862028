export const ADDRESS_SELECT = 'ADDRESS_SELECT';
export const selectAddress = id => ({
  type: ADDRESS_SELECT,
  payload: { id, isPickupStore: false },
});

export const selectPickupStore = id => ({
  type: ADDRESS_SELECT,
  payload: { id, isPickupStore: true },
});

export const ADDRESS_SELECTED = 'ADDRESS_SELECTED';
export const notifyAddressSelected = (id, info) => ({
  type: ADDRESS_SELECTED,
  payload: { id, info },
});

export const ADDRESS_SAVE = 'ADDRESS_SAVE';
export const saveAddress = fields => ({
  type: ADDRESS_SAVE,
  payload: { fields },
});

export const ADDRESS_SAVED = 'ADDRESS_SAVED';
export const notifyAddressSaved = (addressInfo, info) => ({
  type: ADDRESS_SAVED,
  payload: { addressInfo, info },
});

export const GUEST_SAVE = 'GUEST_SAVE';
export const saveGuest = (fields, shippingFields = null) => {
  const payload = { fields };
  if (shippingFields)
    payload.shippingFields = shippingFields;

  return {
    type: GUEST_SAVE,
    payload,
  };
};

export const CHECKOUT_ADDITIONAL_INFO_SAVE = 'CHECKOUT_ADDITIONAL_INFO_SAVE';
export const saveAdditionalInfo = info => ({
  type: CHECKOUT_ADDITIONAL_INFO_SAVE,
  payload: info,
});

export const DELETE_CHECKOUT_ATTACHMENT = 'DELETE_CHECKOUT_ATTACHMENT';
export const deleteCheckoutAttachment = fileIdToDelete => ({
  type: DELETE_CHECKOUT_ATTACHMENT,
  payload: fileIdToDelete,
});

export const CHECKOUT_INFO_UPDATED = 'CHECKOUT_INFO_UPDATED';
export const checkoutInfoUpdated = info => ({
  type: CHECKOUT_INFO_UPDATED,
  payload: info,
});

export const CHECKOUT_LINES_REQUESTED = 'CHECKOUT_LINES_REQUESTED';
export const requestLines = () => ({
  type: CHECKOUT_LINES_REQUESTED,
});

export const CHECKOUT_SUBMIT_REQUESTED = 'CHECKOUT_SUBMIT_REQUESTED';
export const submitCheckout = additionalInfo => ({
  type: CHECKOUT_SUBMIT_REQUESTED,
  payload: { additionalInfo },
});

export const SHIPPING_METHOD_SELECT = 'SHIPPING_METHOD_SELECT';
export const selectShippingMethod = (id, locationId = null) => ({
  type: SHIPPING_METHOD_SELECT,
  payload: { id, locationId },
});

export const PICKUP_LOCATIONS_REQUESTED = 'PICKUP_LOCATIONS_REQUESTED';
export const loadPickupLocations = methodId => ({
  type: PICKUP_LOCATIONS_REQUESTED,
  payload: { methodId },
});

export const PICKUP_LOCATIONS_RECEIVED = 'PICKUP_LOCATIONS_RECEIVED';
export const pickupLocationsReceived = (methodId, locations) => ({
  type: PICKUP_LOCATIONS_RECEIVED,
  payload: { methodId, locations },
});

export const PAYMENT_SELECT = 'PAYMENT_SELECT';
export const selectPaymentMethod = id => ({
  type: PAYMENT_SELECT,
  payload: { id },
});

export const EXTRA_PAYMENT_DATA_SAVE = 'EXTRA_PAYMENT_DATA_SAVE';
export const saveExtraPaymentData = values => ({
  type: EXTRA_PAYMENT_DATA_SAVE,
  payload: { values },
});

export const EXTRA_PAYMENT_STEP_CHANGED = 'EXTRA_PAYMENT_STEP_CHANGED';
export const notifyExtraPaymentStepChanged = extraPaymentStep => ({
  type: EXTRA_PAYMENT_STEP_CHANGED,
  payload: { extraPaymentStep },
});

export const ADDITIONAL_CUSTOMER_DATA_SAVE = 'ADDITIONAL_CUSTOMER_DATA_SAVE';
export const saveCustomerData = values => ({
  type: ADDITIONAL_CUSTOMER_DATA_SAVE,
  payload: { values },
});

export const ADDITIONAL_CUSTOMER_DATA_STEP_CHANGED = 'ADDITIONAL_CUSTOMER_DATA_STEP_CHANGED';
export const notifyCustomerDataStepChanged = customerDataStep => ({
  type: ADDITIONAL_CUSTOMER_DATA_STEP_CHANGED,
  payload: { customerDataStep },
});

export const CHECKOUT_CREDITNOTELIMIT_REQUESTED = 'CHECKOUT_CREDITNOTELIMIT_CHAGEN';
export const checkoutCreditNotelimitChange = info => ({
  type: CHECKOUT_CREDITNOTELIMIT_REQUESTED,
  payload: { info },
});

export const CHECKOUT_CREDITNOTELIMIT_RECEIVED = 'CHECKOUT_CREDITNOTELIMIT_CHAGEND';
export const checkoutCreditNotelimitChanged = info => ({
  type: CHECKOUT_CREDITNOTELIMIT_RECEIVED,
  payload: info,
});
