export const visualDesignerReady = () => ({
  type: 'VD/WEBSTORE/READY',
});

export const updateHeight = height => ({
  type: 'VD/WEBSTORE/UPDATE_HEIGHT',
  payload: { height },
});

export const addColumn = index => ({
  type: 'VD/ADD_COLUMN',
  payload: { index },
});

export const deleteColumn = id => ({
  type: 'VD/DELETE_COLUMN',
  payload: { id },
});

export const duplicateColumn = id => ({
  type: 'VD/DUPLICATE_COLUMN',
  payload: { id },
});

export const editColumnSettings = id => ({
  type: 'VD/EDIT_COLUMN_SETTINGS',
  payload: { id },
});

export const addRow = rowInfo => ({
  type: 'VD/ADD_ROW',
  payload: { rowInfo },
});

export const deleteRow = index => ({
  type: 'VD/DELETE_ROW',
  payload: { index },
});

export const duplicateRow = index => ({
  type: 'VD/DUPLICATE_ROW',
  payload: { index },
});

export const editRowSettings = (rowIndex, mode, activeTabId) => ({
  type: 'VD/EDIT_ROW_SETTINGS',
  payload: { rowIndex, mode, activeTabId },
});

export const setRowLayout = rowInfo => ({
  type: 'VD/SET_ROW_LAYOUT',
  payload: { rowInfo },
});

export const startAddContentBlock = id => ({
  type: 'VD/START_ADD_CONTENT_BLOCK',
  payload: { id },
});

export const editContentBlock = id => ({
  type: 'VD/EDIT_CONTENT_BLOCK',
  payload: { id },
});

export const deleteContentBlock = id => ({
  type: 'VD/DELETE_CONTENT_BLOCK',
  payload: { id },
});

export const saveContentBlock = (id, blockInfo) => ({
  type: 'VD/SAVE_CONTENT_BLOCK',
  payload: { id, blockInfo },
});

export const moveContentElement = (indexBefore, indexAfter, sourceElementId, targetElementId) => ({
  type: 'VD/MOVE_CONTENT_ELEMENT',
  payload: {
    indexBefore,
    indexAfter,
    sourceElementId,
    targetElementId,
  },
});

export const modifyPageTitle = pageTitle => ({
  type: 'VD/MODIFY_PAGE_TITLE',
  payload: { pageTitle },
});

export const requestVisualDesignerDelete = (title, callbackAction, frontendCallbackAction) => ({
  type: 'VD/DELETE_REQUESTED',
  payload: { title, callbackAction, frontendCallbackAction },
});

export const VISUAL_DESIGNER_RESOURCE_TEXTS_RECEIVED = 'VD/RESOURCE_TEXTS_RECEIVED';
export const VISUAL_DESIGNER_LANGUAGE_CHANGED = 'VD/LANGUAGE_CHANGED';
export const VISUAL_DESIGNER_SET_SETTINGS = 'VD/SET_SETTINGS';
export const VISUAL_DESIGNER_SET_PAGE_ERRORS = 'VD/SET_PAGE_ERRORS';
export const VISUAL_DESIGNER_SET_CUSTOMER_SEGMENT_TITLES = 'VD/SET_CUSTOMER_SEGMENT_TITLES';

export const VISUAL_DESIGNER_LOADED = 'VD/LOADED';
export const visualDesignerLoaded = mode => ({
  type: VISUAL_DESIGNER_LOADED,
  payload: { mode },
});
